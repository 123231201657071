import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"

import "../styles/swiper.scss"
import styled from "styled-components"
import Img from "gatsby-image"
import { Video } from "./video"

SwiperCore.use([Navigation])

export const StyledSwiperWrapper = styled.div`
  margin-bottom: 50px;
  margin-top: 50px;
`
const VierSwiper = ({ params, items, children }) => {

  if (children !== undefined) {
    return (
      <StyledSwiperWrapper>
        <Swiper {...params}
                children={children}
        />
      </StyledSwiperWrapper>
    )
  }

  if (items.length) {

    return (
      <StyledSwiperWrapper>
        <Swiper {...params}>
          {items.map((item, index) => (
              <SwiperSlide key={index}>
                <div className={"container"} css={{
                  boxSizing: "border-box",
                }}>
                  <div className={"row justify-content-center"}>
                    <div className={"col-12"}>

                      {item.videourl &&
                      <div className={"embed-responsive embed-responsive-16by9"}>
                        <Video ID={item.videourl} playing={false}/>
                      </div>
                      }

                      {item.localFile &&
                      <Img fluid={item.localFile.childImageSharp.fluid}
                           sizes={{ ...item.localFile.childImageSharp.fluid }}
                           imgStyle={{ objectFit: "contain" }}
                           style={{ maxHeight: "calc(100vh - 100px)" }}
                      />
                      }
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ),
          )}
        </Swiper>
      </StyledSwiperWrapper>
    )
  }

  return (
    <StyledSwiperWrapper>
      {items.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={"container"} css={{
              boxSizing: "border-box",
            }}>
              <div className={"row justify-content-center"}>
                <div className={"col-12"}>
                  {item.videoUrl &&
                  <div className={"embed-responsive embed-responsive-16by9"}>
                    <Video ID={item.videoUrl} playing={false}/>
                  </div>
                  }

                  {item.localFile &&
                  <Img fluid={item.localFile.childImageSharp.fluid}
                       sizes={{ ...item.localFile.childImageSharp.fluid }}
                       imgStyle={{ objectFit: "contain" }}
                       style={{ maxHeight: "calc(100vh - 100px)" }}
                  />
                  }
                </div>
              </div>
            </div>
          </SwiperSlide>
        ),
      )
      }
    </StyledSwiperWrapper>
  )
}

export default VierSwiper
